import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <Layout>
    <div className="container mx-auto p-6 py-16">
        <div className="flex flex-wrap">
            <SEO title="404: Not found"  />

            <section className={`w-full p-3`}>                  
                <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary">
                    Not Found
                </h1>
                <div id="main-content-block">
                    Oh no! We couldn't find what you were looking for. 
                </div>
            </section>

        </div>
    </div>
  </Layout>
)

export default NotFoundPage
